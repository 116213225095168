
		import Async from "../../../node_modules/.pnpm/@preact+async-loader@3.0.1_preact@10.6.6/node_modules/@preact/async-loader/async.js";

		function load(cb) {
			require.ensure([], function (require) {
				var result = require("!!../../../node_modules/.pnpm/babel-loader@8.2.3_a58183228c2c7b1a132de84b2b6f9adf/node_modules/babel-loader/lib/index.js??ref--4!./index.tsx");
				typeof cb === 'function' && cb(result);
			}, "route-notfound");
		}

		export default Async(load);
	